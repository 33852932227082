<template>
  <fragment>
    <!--没有子导航-->
    <el-menu-item v-show="!item.children" :index="item.path">
      <i v-if="item.icon" :class="item.icon"></i>
      <span slot="title">{{ item.title }}</span>
    </el-menu-item>
    <!--有子导航-->
    <el-submenu
      v-show="item.children"
      :index="String(itemIndex + 1) + item.title"
    >
      <template slot="title">
        <!--如果item有icon才添加icon图标-->
        <i v-if="item.icon" :class="item.icon"></i>
        <span slot="title">{{ item.title }}</span>
      </template>
      <!--判断子导航是否存在下一级的子导航，如果没有则渲染自己，如果有则递归组件-->
      <!--如果子导航中没有存在下一级的子导航 则是<el-menu-item> 否则为<el-submenu>-->
      <template
        v-for="(cItem, cIndex) in item.children"
        :index="String(itemIndex + 1 + '-' + cIndex + 1)"
      >
        <el-menu-item
          v-show="!cItem.children"
          :index="cItem.path"
          :key="cItem.path"
          >{{ cItem.title }}</el-menu-item
        >
        <el-submenu
          v-show="cItem.children"
          :index="String(itemIndex + 1 + '-' + cIndex + 1)"
          :key="String(itemIndex + 1 + '-' + cIndex + 1)"
        >
          <i v-if="item.icon" :class="cItem.icon"></i>
          <span slot="title">{{ cItem.title }}</span>
          <!--递归自己 遍历子..导航-->
          <template v-for="(item, parentIndex) in cItem.children">
            <SideNav
              :item="item"
              :index="parentIndex + 1"
              :key="parentIndex"
            ></SideNav>
          </template>
        </el-submenu>
      </template>
    </el-submenu>
  </fragment>
</template>

<script>
export default {
  name: "SideNav",
  props: {
    item: {
      type: Object,
      required: true,
    },
    itemIndex: {
      type: Number,
      required: false,
    },
  },
};
</script>
<style scoped>
.el-submenu [class^="fa"] {
  vertical-align: middle;
  margin-right: 5px;
  width: 24px;
  text-align: center;
  font-size: 16px;
}
:deep(.el-submenu__title) {
  font-size: 14px;
  font-weight: 400;
  color: #2e2e2e;
}
.el-menu-item.is-active {
  color: #0279ff;
  font-size: 14px;
  font-weight: bolder;
  border-bottom: 2px solid #0279ff;
}
:deep(.el-submenu__icon-arrow){
  position: static;
  vertical-align: middle;
  margin-left: 6px;
  margin-top: 0px;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #0082fc;
}
</style>

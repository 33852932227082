<template>
  <el-menu
    :default-active="defaultActive"
    :unique-opened="true"
    class="el-menu-vertical"
    ref="menu"
    router
    mode="horizontal"
  >
    <!--动态生成sideItem-->
    <template v-for="(item, itemIndex) in childsideList">
      <span :key="itemIndex">
        <SideNav :item="item" :itemIndex="itemIndex"></SideNav>
      </span>
    </template>
  </el-menu>
</template>
<script>
import SideNav from "./SideNav";
export default {
  name: "MenuList",
  components: {
    SideNav,
  },
  props: {
    childsideList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultActive: "",
    };
  },
  watch: {
    childsideList: {
      //深度监听，可监听到对象、数组的变化
      handler(newV, oldV) {
        this.defaultActive = newV[0].path;
      },
    },
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.el-menu-vertical {
  display: flex;
  cursor: pointer;
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
.el-menu.el-menu--horizontal > span {
  width: 20%;
}
</style>
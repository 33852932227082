<template>
    <div class="footer-box">
        <el-row class="footer-box-top">
            <el-col style="width:32%">
                <div>
                    <img src="@/assets/img/home/hotline.png">
                    <span style="padding-left:11px">售前咨询热线</span>
                </div>
                <div class="footer-box-top-tel">0546-777720</div>
                <div style="margin-top: 64px;">
                    <img src="@/assets/img/home/wx.png">
                    <img style="margin-left: 20px;" src="@/assets/img/home/wb.png">
                </div>
            </el-col>
            <el-col style="width:17%">
                <div class="title-type">胜软数字咨询</div>
                <div class="list-box" @click="goto('/numberrefer/strategicplan')">战略规划</div>
                <div class="list-box" @click="goto('/numberrefer/dataprogram')">数据规划</div>
                <div class="list-box" @click="goto('/numberrefer/bigData')">大数据&AI应用咨询</div>
            </el-col>
            <el-col style="width:17%">
                <div class="title-type">解决方案</div>
                <div class="list-box" @click="goto('/industrysolution/digitalgovernment/smartcity')">智慧城市</div>
                <div class="list-box" @click="goto('/industrysolution/digitalgovernment/regionalgovernance')">城市应急</div>
                <div class="list-box" @click="goto('/industrymake/internet')">工业互联网</div>
            </el-col>
            <el-col style="width:17%">
                <div class="title-type">分支机构</div>
                <div class="list-box">超思唯科</div>
                <div class="list-box">青岛创立</div>
                <div class="list-box">胜软石油</div>
            </el-col>
            <el-col style="width:17%">
                <div class="title-type">关于胜软</div>
                <div class="list-box" @click="goto('/aboutVictorySoft/gsjs')">公司介绍</div>
                <div class="list-box" @click="goto('/aboutVictorySoft/news')">新闻咨询</div>
                <div class="list-box" @click="goto('/aboutVictorySoft/zxns')">招贤纳士</div>
                <div class="list-box" @click="goto('/aboutVictorySoft/ryzz')">荣誉资质</div>
            </el-col>
        </el-row>
        <div class="footer-box-bottom">
            <span>淄博胜软科技有限公司版权所有</span>
            <span> 鲁ICP备2022027535号</span>
            <span> | </span>
            <span> 法律声明</span>
            <span> 隐私保护</span>
        </div>
    </div>
</template>
<script>
export default {
    methods:{
        goto(route){
            this.$router.push({path:route})
        }
    }
}
</script>
<style scoped>
*{
    box-sizing: border-box;
}
.footer-box{
    width: 100%;
    height: 400px;
    background: #1B1C20;
    padding: 103px 365px 0 365px;
    /* background-image: url('@/assets/img/home/2.png');
    background-size: 100%; */
    background-position: bottom center;
}
.footer-box-top{
    width: 100%;
    height: 225px;
    border-bottom: 1px solid #303138;
    display: flex;
}
.footer-box-top .el-col{
    height: 100%;
    padding: 0 12px;
}
.footer-box-top .el-col div{
    display: flex;
    align-items: center;
    font-size: 18px;
}
.footer-box-top .el-col div span{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
.footer-box-top-tel{
    padding-top: 20px;
    font-size: 30px;
    font-family: 'EnigmaticUnicode';
    font-weight: 400;
    color: #FFFFFF;
}
.title-type{
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    margin-bottom: 10px;
}
.list-box{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #B5B7BC;
    margin-top: 15px;
    cursor: pointer;
}
.footer-box-bottom{
    width: 100%;
    height: 72px;
    text-align: center;
    line-height: 72px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
}
</style>
<template>
  <div class="menuBox">
    <!-- logo -->
    <img src="@/assets/img/logo.png" alt="" srcset="" />
    <!-- 菜单 -->
    <div>
      <MenuList :childsideList="childsideList"></MenuList>
    </div>
    <!-- 联系电话 -->
    <div>
      <img src="@/assets/img/phone.png" alt="" srcset="" style="vertical-align:middle;" />
      <span>0546-7777200</span>
    </div>
  </div>
</template>
<script>
import MenuList from "@/components/common/MenuList";
export default {
  name: "topNavMenu",
  components: {
    MenuList,
  },
  data() {
    return {
      /* 侧边栏传参 */
      childsideList: [
        {
          title: "首页",
          path:"/home"
        },
        {
          title: "数字咨询",
          children: [
            {
              title: "战略规划",
              path: "/numberrefer/strategicplan",
            },
            {
              title: "数据规划",
              path: "/numberrefer/dataprogram",
            },
            {
              title: "大数据&AI应用咨询",
              path: "/numberrefer/bigData",
            },
          ],
        },
        {
          title: "行业解决方案",
          children: [
            {
              title: "数字政府",
              children: [
                {
                  title: "智慧政务整体解决方案",
                  path: "/industrysolution/digitalgovernment/smartgovernment",
                },
                {
                  title: "智慧法院整体解决方案",
                  path: "/industrysolution/digitalgovernment/smartcourt",
                },
                {
                  title: "智慧检务整体解决方案",
                  path: "/industrysolution/digitalgovernment/smartinspect",
                },
                {
                  title: "市域治理解决方案",
                  path: "/industrysolution/digitalgovernment/regionalgovernance",
                },
                {
                  title: "市政智能应急管理解决方案",
                  path: "/industrysolution/digitalgovernment/emergencymanage",
                },
                {
                  title: "智慧城市整体解决方案",
                  path: "/industrysolution/digitalgovernment/smartcity",
                },
              ],
            },
            {
              title: "工业制造",
              children: [
                {
                  title: "工业互联网平台与应用整体解决方案",
                  path: "/industrymake/internet",
                },
                {
                  title: "数字化产品运营解决方案",
                  path: "/industrymake/digitization",
                },
                {
                  title: "智慧园区(工业)整体解决方案",
                  path: "/industrymake/wisdompark",
                },
              ],
            },
          ],
        },
        {
          title: "基础信息服务",
          children: [
            {
              title: "Devops",
              path: "/basicinformationservice/devops",
            },
            {
              title: "云平台",
              path: "/basicinformationservice/cloudplatform",
            },
            {
              title: "AI平台",
              path: "/basicinformationservice/aiplatform",
            },
            {
              title: "IT集成与运维服务",
              path: "/basicinformationservice/integratedoperations",
            },
          ],
        },
        {
          title: "关于胜软",
          children: [
            {
              title: "公司介绍",
              path: "/aboutVictorySoft/gsjs",
            },
            {
              title: "新闻资讯",
              path: "/aboutVictorySoft/news",
            },
            {
              title: "招纳贤士",
              path: "/aboutVictorySoft/zxns",
            },
            {
              title: "荣誉资质",
              path: "/aboutVictorySoft/ryzz",
            },
            {
              title: "合作伙伴",
              path: "/aboutVictorySoft/hzhb",
            },
            {
              title: "联系我们",
              path: "/aboutVictorySoft/lxwm",
            },
          ],
        },
      ],
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.menuBox {
  width: 100%;
  height: 60px;
  line-height: 60px;
  justify-content: space-around;
  align-items: center;
  background: #ffffff;
  display: flex;
}
.menuBox>div:nth-child(2){
  width: 650px;
}
</style>
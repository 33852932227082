import Vue from 'vue'
import VueRouter from 'vue-router'
import PublicPage from '../views/PublicPage.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'publicpage',
    component: PublicPage,
    redirect: "/home",
    meta: {
      info: '首页'
    },
    children: [
      {
        path: "/home",
        meta: {
          info: '首页'
        },
        component: () => import('../views/HomeView.vue'),
      },
      {
        path: "/numberrefer",
        meta: {
          info: '数字咨询'
        },
        redirect: "/numberrefer/strategicplan",
        component: () => import('../views/NumberRefer'),
        children: [
          {
            path: "/numberrefer/strategicplan",
            name: "strategicplan",
            meta: {
              info: '战略规划'
            },
            component: () => import('../views/NumberRefer/StrategicPlan.vue')
          },
          {
            path: "/numberrefer/dataprogram",
            name: "dataprogramindex",
            meta: {
              info: '数据规划'
            },
            redirect: "/numberrefer/dataprogram/home",
            component: () => import('../views/NumberRefer/DataProgram'),
            children: [
              {
                path: "/numberrefer/dataprogram/home",
                name: "dataprogram",
                meta: {
                  info: ''
                },
                component: () => import('../views/NumberRefer/DataProgram/dataProgram.vue'),
              },
              {
                path: "/numberrefer/dataprogram/datagovern",
                name: "datagovern",
                meta: {
                  info: '数据治理解决方案'
                },
                component: () => import('../views/NumberRefer/DataProgram/datajjfa.vue')
              },
              {
                path: "/numberrefer/dataprogram/datalake",
                name: "datalake",
                meta: {
                  info: '数据湖解决方案'
                },
                component: () => import('../views/NumberRefer/DataProgram/datasjhjjfa.vue')
              }
            ]
          },
          {
            path: "/numberrefer/bigData",
            name: "bigdata",
            meta: {
              info: '大数据&AI应用咨询'
            },
            component: () => import('../views/NumberRefer/bigData.vue')
          }
        ]
      },
      {
        path: "/industrysolution",
        meta: {
          info: "行业解决方案"
        },
        redirect: "/industrysolution/digitalgovernment",
        component: () => import('../views/IndustrySolution'),
        children: [
          {
            path: "/industrysolution/digitalgovernment",
            meta: {
              info: "数字政府"
            },
            redirect: "/industrysolution/digitalgovernment/smartgovernment",
            component: () => import('../views/IndustrySolution'),
            children: [
              {
                path: "/industrysolution/digitalgovernment/smartgovernment",
                name: "smartgovernment",
                meta: {
                  info: "智慧政务整体解决方案"
                },
                component: () => import('../views/IndustrySolution/DigitalGovernment/SmartGovernment.vue'),
              },
              {
                path: "/industrysolution/digitalgovernment/smartcourt",
                name: "smartcourt",
                meta: {
                  info: "智慧法院整体解决方案"
                },
                component: () => import('../views/IndustrySolution/DigitalGovernment/SmartCourt.vue'),
              },
              {
                path: "/industrysolution/digitalgovernment/smartinspect",
                name: "smartinspect",
                meta: {
                  info: "智慧检务整体解决方案"
                },
                component: () => import('../views/IndustrySolution/DigitalGovernment/SmartInspect.vue'),
              },
              {
                path: "/industrysolution/digitalgovernment/regionalgovernance",
                name: "regionalgovernance",
                meta: {
                  info: "市域治理解决方案"
                },
                component: () => import('../views/IndustrySolution/DigitalGovernment/RegionalGovernance.vue'),
              },
              {
                path: "/industrysolution/digitalgovernment/emergencymanage",
                name: "emergencymanage",
                meta: {
                  info: "市政智能应急管理解决方案"
                },
                component: () => import('../views/IndustrySolution/DigitalGovernment/EmergencyManage.vue'),
              },
              {
                path: "/industrysolution/digitalgovernment/smartcity",
                name: 'smartcity',
                meta: {
                  info: "智慧城市整体解决方案"
                },
                component: () => import('../views/IndustrySolution/DigitalGovernment/SmartCity.vue'),
              }
            ]
          }
        ]
      },
      {
        path: "/industrymake",
        meta: {
          info: "工业制造"
        },
        redirect: "/industrymake/internet",
        component: () => import('../views/IndustrySolution/IndustryMake'),
        children: [
          {
            path: "/industrymake/internet",
            name: "internet",
            meta: {
              info: '工业互联网平台与应用整体解决方案'
            },
            component: () => import('../views/IndustrySolution/IndustryMake/Internet.vue')
          },
          {
            path: "/industrymake/digitization",
            name: "digitization",
            meta: {
              info: '数字化产品运营解决方案'
            },
            component: () => import('../views/IndustrySolution/IndustryMake/Digitization.vue')
          },
          {
            path: "/industrymake/wisdompark",
            name: "wisdompark",
            meta: {
              info: '智慧园区(工业)整体解决方案'
            },
            component: () => import('../views/IndustrySolution/IndustryMake/WisdomPark.vue')
          }
        ]
      },
      {
        path: "/basicinformationservice",
        meta: {
          info: '基础信息服务'
        },
        redirect: "/basicinformationservice/devops",
        component: () => import('../views/BasicInformationService'),
        children: [
          {
            path: "/basicinformationservice/devops",
            name: "devops",
            meta: {
              info: '胜软研发管理平台'
            },
            component: () => import('../views/BasicInformationService/Devops.vue')
          },
          {
            path: "/basicinformationservice/cloudplatform",
            name: "cloudplatform",
            meta: {
              info: '企业云平台'
            },
            component: () => import('../views/BasicInformationService/CloudPlatform.vue')
          },
          {
            path: "/basicinformationservice/aiplatform",
            name: "aiplatform",
            meta: {
              info: 'AI平台'
            },
            component: () => import('../views/BasicInformationService/AIPlatform.vue')
          },
          {
            path: "/basicinformationservice/integratedoperations",
            name: "integratedoperations",
            meta: {
              info: 'IT集成与运维服务'
            },
            component: () => import('../views/BasicInformationService/IntegratedOperations.vue')
          },
        ]
      },
      {
        path: "/aboutVictorySoft",
        meta: { info: '关于胜软' },
        component: () => import('../views/gysr'),
        redirect: "/aboutVictorySoft/gsjs",
        children: [
          {
            path: "/aboutVictorySoft/gsjs",
            name: "gsjs",
            meta: {
              info: '公司介绍'
            },
            component: () => import('../views/gysr/gsjs.vue')
          },
          {
            path: "/aboutVictorySoft/news",
            name: "new",
            meta: {
              info: '新闻资讯'
            },
            component: () => import('../views/gysr/xwzx.vue')
          },
          {
            path: "/aboutVictorySoft/zxns",
            name: "zxns",
            meta: { info: '招纳贤士' },
            component: () => import('../views/gysr/zxns.vue')
          },
          {
            path: "/aboutVictorySoft/ryzz",
            name: "ryzz",
            meta: { info: '荣誉资质' },
            component: () => import('../views/gysr/ryzz.vue')
          },
          {
            path: "/aboutVictorySoft/hzhb",
            name: "hzhb",
            meta: { info: '合作伙伴' },
            component: () => import('../views/gysr/hzhb.vue')
          },
          {
            path: "/aboutVictorySoft/lxwm",
            name: "lxwm",
            meta: { info: '联系我们' },
            component: () => import('../views/gysr/lxwm.vue')
          },
          {
            path: "/aboutVictorySoft/recruit",
            name: "recruit",
            meta: { info: '招聘详情' },
            component: () => import('../views/gysr/recruit.vue')
          }
        ]
      }
    ]
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 路由跳转定位顶部
  scrollBehavior(to, from, savePosition) {
    return {
      y: 0
    }
  }
})

export default router

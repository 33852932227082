<template>
  <div class="header-box">
    <!-- 顶部导航 -->
    <TopNavMenu></TopNavMenu>
    <router-view />
    <AboutView></AboutView>
    <!--  -->
    <el-backtop>
      <img class="toTop" src="@/assets/img/toTop.png" alt="">
    </el-backtop>
  </div>
</template>
<script>
import TopNavMenu from "@/components/page/topNavMenu.vue";
import AboutView from "@/views/AboutView.vue";
export default {
  components: {
    TopNavMenu,
    AboutView,
  },
};
</script>
<style scoped>
.toTop{
  width: 48px;
  height: 48px;
}
</style>